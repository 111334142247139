//React, React Router, Formik
import React, {useState} from 'react';
import Link from "next/link";
import SignInForm from "@ui/SignInForm/SignInForm";
import SocialAuthButton from "@ui/SocialAuthButton/SocialAuthButton";
import {AUTH} from "@helper/constants/routes";
import SignupForm from "@ui/SignupForm/SignupForm";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Button, Grid, Typography} from "@material-ui/core";

//Redux
import {useSelector} from "react-redux";

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        ...theme.typography.lmsCenterPage,
        marginTop: theme.spacing(12.5)
    },
    header: {
        textAlign: 'center',
        "& .MuiTypography-root": {
            fontSize: '26px',
            fontWeight: 'bold',
            lineHeight: '32px'
        }
    },
    contentWrapper: {
        maxWidth: 330,
        width: '100%',
        margin: theme.spacing(0, 'auto')
    },
    socialAuthWrapper: {
        marginTop: theme.spacing(12)
    },
    signInButton: {
        ...theme.typography.lmsButton1,
        marginTop: theme.spacing(6),
        width: '100%',
        "& .MuiButton-label": {
            ...theme.typography.largeButton,
        }
    },
    fpWrapper: {
        margin: theme.spacing(4, 0, 0),
        "& a": {
            fontSize: '15px',
            fontWeight: 'bold',
            color: theme.palette.grey.persian_blue,
            "&:hover": {
                textDecoration: 'underline',
            },
            "&::selection": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main
            },
        }
    },
    signupOrSigninTextWrapper: {
        marginBottom: theme.spacing(25),
        textAlign: 'center',
        "& .MuiTypography-root": {
            fontSize: '15px',
            "& .MuiButton-root": {
                all: 'unset',
                "& .MuiButton-label": {
                    fontSize: '15px',
                    fontWeight: 'bold',
                    color: theme.palette.grey.persian_blue,
                    "&:hover": {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    },
                }
            },
        },
        "&::selection": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main
        },
    },
    separator: {
        margin: theme.spacing(6, 0),
        height: 1,
        borderTop: `1px solid ${theme.palette.primary.main}`
    },
}));

const Login = () => {

    const classes = useStyles();

    const [showSignUpForm, setShowSignUpForm] = useState(false);

    const {appMeta} = useSelector(state => state.siteDetail);

    const handleShowSignUpForm = (value) => {
        setShowSignUpForm(value);
    }

    return (
        <Grid container direction="column" alignItems="center" className={classes.root}>

            {/*Header*/}
            <Grid item className={classes.header}>
                <Typography>
                    Sign in to your {appMeta?.appName} account
                </Typography>
            </Grid>

            <Grid item container direction="column" className={classes.contentWrapper}>
                {/*Social Auth*/}
                <Grid item className={classes.socialAuthWrapper}>
                    <SocialAuthButton variant="grey"/>
                </Grid>

                <Grid item>

                    {showSignUpForm ? <SignupForm />: <SignInForm/>}
                </Grid>

                <Grid item className={classes.fpWrapper}>
                    <Link href={AUTH.forgotPassword}>
                        Forgot password?
                    </Link>
                </Grid>

                {/*Separator*/}
                <Grid item>
                    <div className={classes.separator}/>
                </Grid>

                <Grid item className={classes.signupOrSigninTextWrapper}>
                    {
                        showSignUpForm ?
                            <Typography>
                                Already have an account? &nbsp; <Button onClick={() => handleShowSignUpForm(false)}>Sign
                                In.</Button>
                            </Typography> :
                            <Typography>
                                Not a member yet? &nbsp; <Button onClick={() => handleShowSignUpForm(true)}>Sign
                                Up.</Button>
                            </Typography>
                    }
                </Grid>

            </Grid>

        </Grid>
    );
};

export default Login;
