//React, React Router, Formik
import React from 'react';
import Login from "@modules/login/Login";
import {wrapper} from "@store/store";
import {protectedRoutes} from "@helper/constants/routes";
import {useRouter} from "next/router";
import Head from "next/head";
import {useSelector} from "react-redux";

//Redux

//Assets

//This method must be implemented for Nprogress to work properly
export const getServerSideProps = wrapper.getServerSideProps(
    store => async (context) => {
        //console.log('CONTEXT: ', context);
    }
)

const Index = () => {

    const {appMeta} = useSelector(state => state.siteDetail);

    return (
        <>
            <Head>
                <title>{`Sign In - ${appMeta?.appName}`}</title>
            </Head>
            <Login />
        </>
    )
};

export default Index;
